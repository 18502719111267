<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button type="button" @click="goBack()" class="btn btn-inverse" title="Kembali">Kembali</button>
        </span>
      </h3>
      <hr>

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <gtTabs>
            <gtTab title="Profil">
              <div class="col-md-6 pull-left">
                <small class="text-muted">Pengirim</small>
                <h6>{{ state.detail.pengirim === "" ? '-' : state.detail.pengirim }}</h6>
                <small class="text-muxed">Tanggal Diterima</small>
                <h6>{{ tanggalditerima === "" ? '-' : tanggalditerima }}</h6>
                <small class="text-muxed">Kepada</small>
                <h6>{{ state.detail.kepada === "" ? '-' : state.detail.kepada }}</h6>
                <small class="text-muxed">Tujuan</small>
                <h6>{{ state.detail.tujuan === "" ? '-' : state.detail.tujuan }}</h6>
                <small class="text-muxed">Tembusan</small>
                <h6>{{ state.detail.tembusan === "" ? '-' : state.detail.tembusan }}</h6>
                <small class="text-muted">Indeks Surat</small>
                <h6>{{ state.detail.indeks === "" ? '-' : state.detail.indeks }}</h6>
              </div>

              <div class="col-md-6 pull-left">
                <small class="text-muted">Nomor Surat</small>
                <h6>{{ state.detail.nomor_surat === "" ? '-' : state.detail.nomor_surat }}</h6>
                <small class="text-muted">Nomor Dokumen</small>
                <h6>{{ state.detail.nomor_dokumen === "" ? '-' : state.detail.nomor_dokumen }}</h6>
                <small class="text-muted">Tanggal Dokumen</small>
                <h6>{{ tanggaldokumen === "" ? '-' : tanggaldokumen }}</h6>
                <small class="text-muted">Perihal</small>
                <h6>{{ state.detail.perihal === "" ? '-' : state.detail.perihal }}</h6>
                <small class="text-muted">Keterangan</small>
                <h6>{{ state.detail.keterangan === "" ? '-' : state.detail.keterangan }}</h6>
                <small class="text-muted">File</small>
                <h6>{{ state.detail.file === "" ? '-' : state.detail.file }}</h6>
              </div>
              <div class="col-md-12 justify-content-md-center">
                <div class="col-md-6 pull-left">
                  <small class="text-muted p-t-30 db">Deskripsi</small>
                  <h6>{{state.detail.description === ""? '-' : state.detail.description}}</h6>
                  <small class="text-muted p-t-30 db">Submitted</small>
                  <h6>{{submitted}}</h6>
                  <small class="text-muted p-t-30 db">Modified</small>
                  <h6>{{modified}}</h6>
                </div>
              </div>
            </gtTab>

            <gtTab title="Pendidikan">b</gtTab>

            <gtTab title="Jabatan">c</gtTab>
            <gtTab title="Pelatihan">d</gtTab>
            <gtTab title="Jenis Kopetensi">e</gtTab>
          </gtTabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import ImageItem from "@/components/img/imgItem";
import { gtTab, gtTabs } from "@/components/gtTabs";
export default {
  components: {
    RotateSquare5,
    ImageItem,
    gtTab,
    gtTabs
  },
  computed: {
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.suratMasuk;
    },
    tanggalditerima() {
      return this.formatDate(this.state.detail.tanggal_diterima);
    },
    tanggaldokumen() {
      return this.formatDate(this.state.detail.tanggal_dokumen);
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    }
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false
      };
      this.$store.commit("suratMasuk/STATE", state);
      this.$store.dispatch("suratMasuk/getSuratMasukById", this.$route.params);
    },
    goBack() {
      this.$store.dispatch("suratMasuk/onCancel");
    }
  }
};
</script>
